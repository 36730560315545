import Vue from 'vue/dist/vue.esm'
import axios from 'axios';
import qs from 'qs';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
    const selector = '.transaction_item_cars-edit';
    const elements = document.querySelectorAll(selector);
    if (elements.length === 0) {
        return
    }

    new Vue({
      el: ".transaction_item_cars-edit",
      data: function (){
        return {
          activetab: 1,
          csrfToken: '',
          payment:{},
          bankFee:0,
          childItems:{
            car:{},
            order:{},
            invoice:{
              invoice_prices:{}
            },
            sale:{
              sale_payments:{}
            },
          },
          loadErrorChildItems: '',
          requestItems:{},
          loadErrorRequestItems: '',
          errors:{},
          approvalErrors:{},
          paymentStatusAlert:{},
          Total:'',//（入金額＋銀行手数料）と一致すべき合計
          totalMatch:false,//Totalが（入金額＋銀行手数料）と一致している場合true
          UseCarriedForwardTotal:'',//繰越金利用の合計
          approvalActive:true//承認ボタンの活性化
        }
      },
      mounted: function(){
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        const container = this.$el;//<div class="transaction_item_cars-edit">
        this.payment = JSON.parse(container.getAttribute('data-payment'));
        this.bankFee = JSON.parse(container.getAttribute('data-bank-fee'));
        this.setChildItems(true);
        this.setRequestItems();
      },
      watch:{
      },
      computed:{
      },
      methods: {

        setChildItems(set_input_total = false) {
          return new Promise(async (resolve, reject) => {
            try{
              const response = await axios.get(`/admin/payment_managements/api/${this.payment.id}/transaction_item_cars_child_items.json`)

              this.childItems = response.data
              this.loadErrorChildItems = '';
              
              // //保存済みの内訳を合計し、入金予定額と一致しているか調べる
              let calculate_sum = this.calculateSum()
              this.Total = calculate_sum[0]
              this.UseCarriedForwardTotal = calculate_sum[1]
              this.totalMatch = calculate_sum[2]

              //支払い完了ステータスの確認を促すメッセージ
              for (let key in this.childItems) {
                if (this.childItems[key]['invoice'] && this.childItems[key]['sale']){
                  //通貨はinvoiceの通貨を基準にする
                  var currency = this.childItems[key]['invoice']['currency']
                  var sale_price = 0
                  var invoice_price = 0

                  if (currency == "dollar"){
                    sale_price = this.childItems[key]['sale']['dollar_sales']
                    invoice_price = this.childItems[key]['invoice']['total_price']
                  }
                  else if (currency == "yen"){
                    sale_price = this.childItems[key]['sale']['yen_sales']
                    invoice_price = this.childItems[key]['invoice']['total_price']
                  }
                  //invoiceの通貨が仮想通貨の場合はこの画面で支払い完了ステータスを判定できない

                  //sale_priceとinvoice_priceが同じ。且つ、payment_statusがfalse
                  if (sale_price > 0 && invoice_price > 0 ){
                    var msgs= []
                    if(sale_price == invoice_price && this.childItems[key]['sale']['payment_status'] == false){
                      msgs.push('支払い完了ステータスを確認してください');
                    }else if (sale_price != invoice_price && this.childItems[key]['sale']['payment_status'] == true){
                      msgs.push('支払い完了ステータスを確認してください');
                    }
                    this.paymentStatusAlert[`payment_status_${this.childItems[key]['id']}`] = msgs
                  }
                }
              }
              resolve();
            }catch (error) {
              this.loadErrorChildItems = 'Faild to load items.';
              reject(error)
            }
          })
        },

        //入金確認申請のデータ
        setRequestItems() {
          return new Promise(async (resolve, reject) => {
            try{
              const response = await axios.get(`/admin/payment_managements/api/${this.payment.id}/transaction_item_cars_request_items.json`)
              this.requestItems = response.data
              this.loadErrorRequestItems = '';
              //未承認の次回繰越データが存在する
              if (this.requestItems.find(e => e.item_type == "次回繰越" && e.status != "approved" )){
                this.approvalActive = false
              }else{
                this.approvalActive = true
              }
              resolve();
            }catch (error) {
              this.loadErrorRequestItems = 'Faild to load items.';
              reject(error)
            }
          })
        },
        calculateSum() {
          //内訳の金額の合計
          var total = 0
          var use_carried_forward_total = 0
          var match = false
          if (this.payment.deposit_jpy != null){
            for (let key in this.childItems) {
              if (this.childItems[key]['item_type'] == '海外車両販売' || this.childItems[key]['item_type'] == '次回繰越'){
                total = total + Number(this.childItems[key]['fee_jpy'])
              }
              if (this.childItems[key]['item_type'] == '繰越割当'){
                use_carried_forward_total = use_carried_forward_total + Number(this.childItems[key]['fee_jpy'])
              }
            }
            if ((this.payment.deposit_jpy + this.bankFee) == total){
              match = true
            }
          }else if(this.payment.deposit_usd != "" ){
            for (let key in this.childItems) {
              if (this.childItems[key]['item_type'] == '海外車両販売' || this.childItems[key]['item_type'] == '次回繰越'){
                total = this.getValue(total, Number(this.childItems[key]['fee_usd']), true)
              }
              if (this.childItems[key]['item_type'] == '繰越割当'){
                use_carried_forward_total = this.getValue(use_carried_forward_total, Number(this.childItems[key]['fee_usd']), true)
              }
            }
            if ((this.payment.deposit_usd + this.bankFee) == total){
              match = true
            }
          }
          return [total, use_carried_forward_total, match];
        },
        ////////小数点まるめ誤差対応して足し算／引き算の値を求める
        getValue(value_1,value_2,addition){
          ////////小数点まるめ誤差対応
          // それぞれの小数点の位置を取得
          var dotPosition1 = this.getDotPosition(value_1);
          var dotPosition2 = this.getDotPosition(value_2);
          
          // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
          var max = Math.max(dotPosition1,dotPosition2);
        
          // 大きい方に小数の桁を合わせて文字列化、
          // 小数点を除いて整数の値にする
          var intValue1 = parseInt((value_1.toFixed(max) + '').replace('.', ''));
          var intValue2 = parseInt((value_2.toFixed(max) + '').replace('.', ''));
          
          // 10^N の値を計算
          var power = Math.pow(10,max);
          
          //足し算のケース
          if (addition == true){
            // 整数値で引き算した後に10^Nで割る
            return (intValue1 + intValue2) / power
          //引き算のケース
          }else{
            // 整数値で引き算した後に10^Nで割る
            return (intValue1 - intValue2) / power
          }
        },

        //承認の解除
        deleteChildItem: async function (id,request_payment_confirmation_id = null){
          var confirm_message = "内訳を削除し、入金確認依頼の承認を取り消しますか？"
          if(!confirm(confirm_message)){
            return false;
              } else {
                return new Promise(async (resolve, reject) => {
                  try{
                    const result = await axios.post(
                      `/admin/payment_managements/transaction_item_cars/${this.payment.id}/child_destroy`,
                      {
                        child_id: id
                      },
                      {
                        headers:{
                          "X-Requested-With": "XMLHttpRequest",
                          "X-CSRF-Token": this.csrfToken
                        }
                      }
                    )
                    if (result.data.errors_message != null){
                      //エラーメッセージを表示
                      this.errors[`${id}`] = result.data.errors_message
                      this.setChildItems(true);
                    }else{
                      //承認後、承認を取り消した際に残るメッセージを削除
                      this.approvalErrors[`${request_payment_confirmation_id}`] = ""
                      this.errors[`${id}`] = ""
                      this.setChildItems(true)
                      this.setRequestItems();
                    }
                    resolve();
                  }catch (error) {
                    reject(error)
                  }
                })
          }


        },
        //入金確認申請のデータを承認して取り込む
        approvalRequestItem: async function (id){
          return new Promise(async (resolve, reject) => {
            try{
              const result = await axios.post(
                `/admin/payment_managements/transaction_item_cars/${this.payment.id}/approval`,
                {
                  child_id: id
                },
                {
                  headers:{
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": this.csrfToken
                  }
                }
              )
              this.approvalErrors[`${id}`] = ""
              //入出金管理にエラーがある場合
              if (result.data.errors_message != null){
                this.approvalErrors[`${id}`] = result.data.errors_message
              //sale更新にエラーがあある場合
              }else if (result.data.sale_update_errors != null){
                this.approvalErrors[`${id}`] = result.data.sale_update_errors
              }
              this.setChildItems(true);
              this.setRequestItems();
              resolve();
            }catch (error) {
              reject(error)
              approval_btn.disabled = false;
            }
          })
        },
        //小数点の位置を調べる
        getDotPosition(value){
          // 数値のままだと操作できないので文字列化する
          var strVal = String(value);
          var dotPosition = 0;
            //小数点が存在するか確認
            if(strVal.lastIndexOf('.') !== -1){
              // 小数点があったら位置を取得
              dotPosition = (strVal.length-1) - strVal.lastIndexOf('.');
            }
            return dotPosition;
        },
      },
  })
});
document.addEventListener('turbolinks:visit', () => {
    // if (subscription) {
    //     consumer.subscriptions.remove(subscription)
    // }

    // if ($notification){
    //     $('.toast', $notification).toast('dispose');
    //     $notification.remove();
    // }

    // $notification = null;
});